import {Injectable, ɵɵresolveDocument} from '@angular/core';
import {AthleticEvent} from '../shared/athletic-event';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {of} from 'rxjs/internal/observable/of';
import {tap} from 'rxjs/internal/operators/tap';
import {forEachComment} from 'tslint';


@Injectable({
  providedIn: 'root'
})
export class EventsDataProviderService {
  private api = 'https://12mutf0usi.execute-api.eu-west-1.amazonaws.com/prod';
  private apiKey = '4UqVpNv49n3YZbKRKMZPF4YoM0GDC8QP2X9u8Xkr';
  private headers = new HttpHeaders();
  private  athleticEvents = new Array<AthleticEvent>();

  constructor(private http: HttpClient) {
      this.headers.append('x-api-key', this.apiKey);
  }

  public getAll(): Observable<AthleticEvent[]> {
    if (this.athleticEvents.length === 0) {
      console.log('build cache');
      return this.http.get<any>(`${this.api}/events`, {headers: this.headers})
        .pipe(
          tap( e => {
           this.athleticEvents.push(...e);
          })
        );
    } else {
      console.log('use cache');
      return of(this.athleticEvents);
    }

  }

  public getSingle(id: string): Observable<any> {
    return of(this.athleticEvents.find(e => e.id === id ));
  }
}
