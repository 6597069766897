import {Component, Input, OnInit} from '@angular/core';
import {AthleticEvent} from '../../shared/athletic-event';

@Component({
  selector: 'mm-event-list-item',
  templateUrl: './event-list-item.component.html',
  styleUrls: ['./event-list-item.component.css']
})
export class EventListItemComponent implements OnInit {
  @Input() athleticEvent: AthleticEvent;
  hasLabelU16 = false;
  hasLabelU18 = false;
  hasLabelU20 = false;

  constructor() { }

  ngOnInit() {
    for (const a of this.athleticEvent.agegroups) {
      switch (a.toLocaleUpperCase()) {
        case 'U16':
          this.hasLabelU16 = true;
          break;
        case 'U18':
          this.hasLabelU18 = true;
          break;
        case 'U20':
          this.hasLabelU20 = true;
          break;
      }
    }
  }

}
