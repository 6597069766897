import {Component,  OnInit} from '@angular/core';
import {AthleticEvent} from '../../shared/athletic-event';
import {EventsDataProviderService} from '../../services/events.data-provider.service';

@Component({
  selector: 'mm-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.css']
})
export class EventListComponent implements OnInit {
  athleticEvents: AthleticEvent[];


  constructor(private edp: EventsDataProviderService) {}

  ngOnInit() {
    console.log('EventListComponent - ngOnInit: call');
    this.edp.getAll().subscribe(res => {
      this.athleticEvents = res.sort(
        (a, b) => {
          return (new Date(a.date) as any) - (new Date(b.date) as any);
        }
      );
    });
  }

 public panleft(e: AthleticEvent) {
    console.log(e);
 }

}


