import { Component } from '@angular/core';
import {AthleticEvent} from './shared/athletic-event';


@Component({
  selector: 'mm-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'LaaTeam';
}
