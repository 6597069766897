import {Component,  OnInit} from '@angular/core';
import {EventsDataProviderService} from '../../services/events.data-provider.service';
import {ActivatedRoute} from '@angular/router';
import {AthleticEvent} from '../../shared/athletic-event';

@Component({
  selector: 'mm-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.css']
})
export class EventDetailsComponent implements OnInit {
  athleticEvent: AthleticEvent;
  constructor(
    private ep: EventsDataProviderService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
      const params = this.route.snapshot.paramMap;
      this.ep.getSingle(params.get('id')).subscribe(e => this.athleticEvent = e);
  }


}
